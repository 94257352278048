<template>
  <div class="home">
      <pmt-loading v-if="!user"></pmt-loading>
      <pmt-postman-home v-if="isPostman"/>
      <display-picture-fullscreen/>
  </div>
</template>

<script>
import firebase from 'firebase/app'

export default {
  name: 'AppHome',
  data () {
    return {
    }
  },
  mounted () {
    this.$store.commit('setMasterLoadingStatus', {
      showLoading: true,
      title: this.$t('loading-mess.load-sys'),
      subTitle: this.$t('loading-mess.load-user-info')
    })
  },
  created () {
    firebase.auth().onAuthStateChanged((user) => {
      if (!user) {
        this.$router.replace('login')
      }
    })
    this.verifToday()
    this.$store.commit('setMasterLoadingStatus', {
      showLoading: true,
      title: this.$t('loading-mess.load-data'),
      subTitle: this.$t('loading-mess.load-user-info')
    })
    this.$store.getters.EventBus.$on('showMap', () => {
      this.$store.dispatch('userLoggedModule/updateCurrentNav', { comp: 'maps-user', kAlive: true })
    })
    this.$store.getters.EventBus.$on('selectComponent', (choice) => {
      this.$store.dispatch('userLoggedModule/updateCurrentNav', { comp: choice, kAlive: true })
    })
    this.$store.getters.EventBus.$on('selectComponentTemp', (choice) => {
      this.$store.dispatch('userLoggedModule/updateCurrentNav', { comp: choice, kAlive: false })
    })
  },
  computed: {
    isPostman () {
      return this.$store.getters.isPostman
    },
    user () {
      return this.$store.getters.user
    },
    userIsAuthenticated () {
      if (this.$store.getters.user !== null && this.$store.getters.user !== undefined) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    // $route (to) {
    //   if (this.userIsAuthenticated) {
    //     this.$store.dispatch('setAppParam', { appId: to.params.appId, appParams: to.params.appParams })
    //   }
    // }
  },
  methods: {
    verifToday () {
      const self = this
      const tday = new Date().toLocaleDateString(self.$store.getters.getLocale + '-CA')
      if (tday !== self.$store.getters.today) {
        self.$store.commit('setToday', tday)
      }
      if (self.$store.state.routeDayDataModuleLoadComplete && tday !== self.$store.getters['routeDayDataModule/getCurrentDay']) {
        self.$store.dispatch('routeDayDataModule/initDay', tday)
      }
      setTimeout(function () {
        self.verifToday()
      }, 10000)
    }
  }
}
</script>
